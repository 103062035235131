import React, {Component} from 'react';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';


class App extends Component {
    render() {
        return (
            <div className='App'>


                <div className="Absolute-Center is-Responsive ">
                    <h4 className='text-center text-font text-light'>saumyaroy.com dev 2</h4>
                    <h1 className='text-font text-light'>Site under construction</h1>
                </div>


            </div>
        );
    }
}

export default App;
